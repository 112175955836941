/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button, Image, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-14iqz1p --style3 --full pb--60 pt--60" name={"v01kxbrzml"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/e7b2954f23fe413f995416cf8b8089f9_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--102 title-box--invert" content={"Vinařství<br>&amp; Penzion Knápek&nbsp;<br>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--center" style={{"maxWidth":690}} content={"<span style=\"color: rgb(255, 255, 255);\">Strachotín, Jižní Morava</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"informace-1"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --left el--1 flex--center" anim={"2"} animS={"2"} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--right" animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--style8 title-box--right" content={"O nás"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2 mt--30 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper animS={"3"} style={{"maxWidth":580}}>
              
              <Text className="text-box" content={"Malé rodinné VINAŘSTVÍ KNÁPEK ve Strachotíně na Jižní Moravě, bylo založeno Františkem Knápkem.\n\n"}>
              </Text>

              <Text className="text-box" content={"Vztah k  vínu a vinohradnictví František podědil již po svém otci, se kterým od mládí víno vyráběl. V té době využívali starého kvelbeného sklepa, který je dodnes je funkční.\n\n\n\n"}>
              </Text>

              <Text className="text-box" content={"V roce 1999 František Knápek započal se svými čtyřmi syny výsadbu vlastních vinic. Vinice se nacházejí ve vinařské obci Strachotín, na viniční traťi Šusfeldy a v současné době obhospodařujeme téměř 20 hektarů vinohradu. Nabízíme tak široký sortiment sudových i lahvových moravských vín.\n\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper animS={"3"} style={{"maxWidth":580}}>
              
              <Text className="text-box" content={"Zhruba od roku 2011 začala výstavba budovy vinařství včetně penzionu. Nachází se ve svahu mezi vinicemi nad Strachotínem a svou vyvýšenou polohou tak nabízí jedinečný pohled na Pálavu a Novomlýnské nádrže. V roce 2016 bylo vinařství otevřeno a v létě se ubytovali první hosté.\n\n\n"}>
              </Text>

              <Text className="text-box" content={"Ideální místo především pro partu známých a přátel, kteří milují cykloturistiku a dobré moravské víno! Penzion stojí samostatně (není v zástavbě)."}>
              </Text>

              <Text className="text-box" content={"Nabízíme Vám relax na terase nebo posezení pod pergolou se sklenkou našeho vína. Jsme přesvědčeni, že budete odjíždět plni krásných zážitků na nezapomenutelnou Jižní Moravu.\n\n\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"8267ghbx5b5"} style={{"backgroundColor":"rgba(252,251,250,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper className="pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":800,"backgroundColor":"rgba(30,30,35,1)"}}>
              
              <Title className="title-box title-box--style8 title-box--right" content={"<span style=\"color: rgb(255, 255, 255);\">Penzion</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":333}} content={"<span style=\"color: rgb(191, 172, 152);\">Penzion&nbsp; Knápek nabízí ubytování ve Strachotíně.</span>"}>
              </Text>

              <Button className="btn-box mt--25" use={"page"} href={"/penzion"} content={"Možnosti ubytování"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/11212/670c59cef3fa419380aa99379366cd22_s=660x_.jpeg"} ratio={"3:2"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/670c59cef3fa419380aa99379366cd22_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/670c59cef3fa419380aa99379366cd22_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/670c59cef3fa419380aa99379366cd22_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":"","backgroundColor":"rgba(30,30,35,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/11212/fcc73aa33d9148898d5ffaeaf7798936_s=660x_.jpeg"} ratio={"3:2"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/fcc73aa33d9148898d5ffaeaf7798936_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/fcc73aa33d9148898d5ffaeaf7798936_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/fcc73aa33d9148898d5ffaeaf7798936_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":800,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Title className="title-box title-box--style8 title-box--right" content={"Vinný sklep"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":333}} content={"<span style=\"color: rgb(191, 172, 152);\">Zažijte nezapomenutelné degustace v pravém moravském vinném sklepě.</span><br>"}>
              </Text>

              <Button className="btn-box mt--25" use={"page"} href={"/sklep"} content={"Degustace"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper className="pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":800,"backgroundColor":"rgba(30,30,35,1)"}}>
              
              <Title className="title-box title-box--style8 title-box--right" content={"<span style=\"color: rgb(255, 255, 255);\">Naše vína</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":333}} content={"<span style=\"color: rgb(191, 172, 152);\">Prohlédněte si vína, které máme momentálně v nabídce.</span>"}>
              </Text>

              <Button className="btn-box mt--25" use={"page"} href={"/nase-vina"} content={"Naše vína"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19433/ca232290a70f4de3bdbca6806c44f0aa_s=860x_.JPEG"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/19433/ca232290a70f4de3bdbca6806c44f0aa_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/ca232290a70f4de3bdbca6806c44f0aa_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/ca232290a70f4de3bdbca6806c44f0aa_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/ca232290a70f4de3bdbca6806c44f0aa_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/19433/ca232290a70f4de3bdbca6806c44f0aa_s=2000x_.JPEG 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"0nunhofzg8h"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"11"} place={"Pouzdřanská 340, Strachotín"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"kufbcwm7nka"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="pb--50 pt--50" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" anim={""} animS={"2"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box subtitle-box--left mt--06 pl--0" content={"<span style=\"color: rgb(255, 255, 255);\">VINAŘSTVÍ KNÁPEK</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left mt--02" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">s.r.o.</span>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">Pouzdřanská 340<br>693 01 Strachotín<br>IČO 05868751<br>DIČ CZ05868751</span><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"https://tvorba-stranek-brno.cz/\">Tvorba webových stránek Brno</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":485}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Vinařství — Knápek Libor, František, Radek</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left fs--26 ls--0 mt--06" style={{"maxWidth":1000}} content={"<span style=\"color: rgb(255, 255, 255);\">(420) 777 574 207, 770 634 067, 770 634 047</span><span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Ubytování — Knápková Jana</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left fs--26 ls--0 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">(420) 608 423 866</span><span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"mailto:info@ubytovani-strachotin-knapek.cz\">info@ubytovani-strachotin-knapek.cz</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left mt--0" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"https://www.facebook.com/VinarstviKnapek/\">https://www.facebook.com/VinarstviKnapek/</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2" use={"page"} content={"Úvod"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} content={"Ubytování"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} content={"Vinní sklep"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} content={"Ceník"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}